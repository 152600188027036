import React, { useState, useEffect } from "react"

function Dialog({title, show, onClose = () => {}, onSave = () => {}, children, 
    saveCaption = 'Save changes', 
    saveBtnCls = 'btn-primary',
    saveDisabled = false,
    width = '', 
    errorMessage = '',
    scrollable = false}) {

    useEffect(() => {
        if (show) {
          document.body.classList.add("modal-open")
        } else {
          document.body.classList.remove("modal-open")            
        }
    })
        
    return (
        <div>
            <div className={show ? "modal show" : "modal"} style={{display: show ? "block": "none"}} role="dialog">
                <div className={"modal-dialog " + (scrollable ? " modal-dialog-scrollable " : "") + width} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => onClose()}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div className="modal-body">
                        {errorMessage && <div class="alert alert-warning" role="alert">
                            {errorMessage}
                        </div>}
                        {children}
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => onClose()}>Close</button>
                    {saveCaption && <button disabled={saveDisabled} type="button" className={`btn ${saveBtnCls}`} onClick={() => {if (! saveDisabled) onSave()}}>{saveCaption}</button>
                    }
                    </div>
                </div>
                </div>
            </div>
        {   
            show &&
            <div className="fade modal-backdrop show"></div>
        }
        
      </div>
    );
}

export default Dialog