import React, { useState, useEffect } from "react";
import Dialog from "./Dialog"

function CommangLogDlg({api, show, onClose}) {

    const [loading, setLoading] = useState(true)
    const [skip, setSkip] = useState(0)
    const [timestamp, setTimestamp] = useState("n/a")
    const pageSize = 10
    const page = Math.trunc(skip / pageSize) + 1 //starts with 1
    const [pageCnt, setPageCnt] = useState(10)
    const [log, setLog] = useState([])

    function refresh() {
        setLoading(true)
        api.getLog(skip, pageSize).then(({skip, limit, count, log}) => {
            setLoading(false)
            setPageCnt(Math.ceil(count / pageSize))
            setLog(log)
        })
        api.getDeals().then(workload => {
            setTimestamp(workload.changeInfo.timestamp)
        })
    }
    
    //assume format '2021-05-17T02:14:20.544Z'
    function formatDate(str) {
        return str ? str.substr(0, 10) : "n/a"
    }

    useEffect(() => {
        if (show) {
            refresh()
        }
    }, [skip, show])

    function prevPage() {
        if (skip > 0) {
            setSkip((page - 2) * pageSize)
        }
    }

    function nextPage() {
        if (page < pageCnt) {
            setSkip(page * pageSize)
        }
    }

    function convertCmd(r) {
        let descr
        const c = r.commandInfo

        switch(c.type) {
            case "AssignCmd":
                descr = `Assign ${c.dealId} to ${c.userId} as ${c.role}`
                break
            case "RemoveAssignCmd":
                descr = `Remove ${c.userId} as ${c.role} from ${c.dealId}`
                break
            case "SetFieldsCmd":
                descr = `Change ${c.dealId} details (caption: "${c.caption ? c.caption : "no change"}", due date: ${c.dueDate ? formatDate(c.dueDate) : "no change"}, comment: "${c.comment ? c.comment : "no change"}")`
                break
            case "FlagDealCmd":
                descr = `Flag ${c.dealId}`
                break
            case "SetPipelineCmd":
                descr = `Set deal ${c.dealId} pipeline to ${c.pipeline}`
                break
            case "AddTagCmd":
                descr = `Add tag ${c.tag} to ${c.dealId}.`
                break
            case "RemoveTagCmd":
                descr = `Remove tag ${c.tag} from ${c.dealId}.`
                break
            case "RemoveDealCmd":
                descr = `Delete round permanently ${c.dealId}. Epic - ${c.epicId}, deal name - ${c.caption}`
                break
            case "CreateDealCmd":
                descr = `Create ${c.dealType} '${c.fields.caption}', due ${c.fields.dueDate}. ${c.fields.epic ? "Assign it to epic " + c.fields.epic : ""}`
                break
            case "SaveCmd":
                descr = "Save changes"
                break
            case "UndoCmd":
                descr = "Undo changes"
                break
            default: 
                descr = c.type
        }

        let statusCls, statusBadge
        switch(r.saveStatus) {
            case "saved":
                statusCls = "bg-success"
                statusBadge = "ok"
                break
            case "ready_for_save":
            case "saving":
                statusCls = "bg-info"
                statusBadge = "ok"
                break
            case "draft":
                statusCls = "bg-secondary"
                statusBadge = "ok"
                break
            case "save_error":
                statusCls = "bg-warning"
                statusBadge = "err"
                break
            default:
                statusCls = "bg-warning"
                statusBadge = "n/a"   
        }

        let author = c.author || ""
        if (author.indexOf("@") != 0) {
            author = author.split("@")[0]
        }
        author = author.replace("_", " ")

        return {
            status: r.saveStatus,
            statusCls,
            statusBadge,
            descr,
            author,
            date: formatDate(r.saveEnd || r.saveStart || r.created)
        }

    }

    return <Dialog title='Changes log' show={show} onClose={onClose} saveCaption={null} width="modal-xl" scrollable={true}>
        <table className="table">
            <tr>
                <th></th>
                <th>Description</th>
                <th>Author</th>
                <th>Date</th>
            </tr>
            {log.map(convertCmd).map((r) => 
                <tr>
                    <td><span title={r.status} className={"badge rounded-pill " + r.statusCls}>{r.statusBadge}</span></td>
                    <td>{r.descr}</td>
                    <td>{r.author}</td>
                    <td>{r.date}</td>
                </tr>
            )}
        </table>
        <nav aria-label="Page navigation">
            <ul className="pagination">
                <li className="page-item">
                <a className={page == 1 ? "page-link disabled" : "page-link"} href="#" onClick={prevPage} aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                </a>
                </li>
                <li class="page-item disabled"><a class="page-link" href="#">{page} of {pageCnt}</a></li>
                <li class="page-item">
                    <a className={(page + 1) >= pageCnt ? "page-link disabled" : "page-link"} href="#" onClick={nextPage} aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </li>
                <li class="page-item">
                    <a className="page-link" href="#" onClick={refresh}>
                        <span aria-hidden="true">Refresh</span>
                    </a>
                </li>
            </ul>
        </nav>
        <div>
            Jira timestamp: {timestamp}
        </div>
    </Dialog>
}

export default CommangLogDlg;