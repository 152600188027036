import React from "react";
import Octicon from 'react-octicon'
import ScrollIntoView from 'react-scroll-into-view'
import { useDispatch, useSelector } from 'react-redux'
import {SET_SELECTED_DEAL} from './../redux/store'

function UserAssignments({userId}) {
    const [nonPriorityCollapsed, setNonPriorityCollapsed] = React.useState(true)

    const dispatch = useDispatch()
    const deals = useSelector(state => state.data.deals)
    const users = useSelector(state => state.data.users)
    const user = users.find(u => u.id === userId)

    function getDealDescription(dealId) {
        const deal = deals.find((d) => d.id === dealId)
        if (! deal) {
            return "Not found"
        } else {
            let className;
            let icon = "checklist"
            if (deal.pipeline == "intake") {
                className = "badge-secondary"
            } else {
                if (deal.type === "Initiative" || deal.type === "PlannerTask") {
                    className = "badge-success"
                    icon = "telescope"
                } else {
                    className = "badge-primary"
                }
            }

            return (
                <span className="deal-list-item">
                    <ScrollIntoView selector={`#deal-${deal.id}`}>
                        <Octicon name={icon} /> &nbsp;
                        {
                            deal.pipeline === 'intake' && 
                            <Octicon name="primitive-square"/>
                        }                        
                        {
                            deal.pipeline === 'on_hold' && 
                            <Octicon name="primitive-square"/>
                        }                        
                        {
                            deal.duedate && 
                             <span>
                                 &nbsp;
                                <span className={`badge ${className}`}>{deal.duedate}</span>
                                &nbsp;
                             </span>
                        }
                        {deal.caption}
                    </ScrollIntoView>
                </span>
            )
        }
    }

    function getAssignedTo(user, deals) {
        const assignedTo = []
        deals.forEach((d) => {
            if (d.team.find((a) => a.user_id === user.id)) {
                assignedTo.push(d.id)
            }
        })
        const pipelineIdx = ["in_progress", "intake", "initiative_with_deadline", "initiative_no_deadline"]
        return assignedTo.sort((a, b) => {
            const dA = deals.find((d) => d.id === a)
            const dB = deals.find((d) => d.id === b)
            const idxA = pipelineIdx.indexOf(dA.pipeline)
            const idxB = pipelineIdx.indexOf(dB.pipeline)
            if (idxA != idxB) {
                return idxA > idxB ? 1 : -1
            }
            if (dA.duedate == dB.duedate) {
                return 0
            }
            return dA.duedate > dB.duedate ? 1 : -1
        })
    }

    function renderAssignments() {
        const assignedTo = getAssignedTo(user, deals)
        if (assignedTo.length) {
            //["in_progress", "intake", "initiative_with_deadline", "initiative_no_deadline"]
            const priorityBatch = assignedTo.filter((dealId) => {
                const deal = deals.find((d) => d.id === dealId);
                return deal.pipeline === 'in_progress' || deal.pipeline === 'intake';
            });
            const nonpriorityBatch = assignedTo.filter((dealId) => {
                const deal = deals.find((d) => d.id === dealId);
                return deal.pipeline !== 'in_progress' && deal.pipeline !== 'intake';
            });

            return (<>
                <ul class="list-group list-group-flush">
                    {priorityBatch.map((dealId, i) => (
                        <li onClick={() => dispatch({type: SET_SELECTED_DEAL, value: dealId})} className="list-group-item" key={i}>{getDealDescription(dealId)}</li>
                    ))} 
                    <li onClick={() => setNonPriorityCollapsed(! nonPriorityCollapsed)} className="small list-group-item list-group-item-action" key="priorityBatch-others">Other assignments <span className="badge badge-secondary">{nonpriorityBatch.length}</span></li>
                </ul>
                <ul class={`list-group list-group-flush collapse ${nonPriorityCollapsed ? "" : "show"}`}>
                    {nonpriorityBatch.map((dealId, i) => (
                        <li onClick={() => dispatch({type: SET_SELECTED_DEAL, value: dealId})} className="list-group-item" key={i}>{getDealDescription(dealId)}</li>
                    ))} 
                </ul>
            </>)
        } else {
            return (
                <ul class="list-group list-group-flush">
                        <li className="list-group-item">No assignments</li>
                </ul>
            )
        }
    }

    function getEventIcon(e) {
        let severity = 'primary'
        let glyph = 'es-bell'
        if (e.type === 'vacation') {
            glyph = 'es-beach'
            severity = 'warning'
        } else if (e.type === 'travel') {
            glyph = 'es-plane'
            severity = 'primary'
        }
        return (
            <span>
            <span className={`badge badge-${severity}`}>
                <span className={`es ${glyph}`}></span>
            </span>
            &nbsp;
            </span>
        )
    }

    if (user) {
        return (
            <div className="user-assignment-component card">
                <div className="card-header">
                    <Octicon mega name="person"/>
                    <span className="user-component-caption">{user.caption}</span>
                </div>
                {renderAssignments()}
                <div className="card-header">
                    Calendar
                </div>
                <ul class="list-group list-group-flush">
                    {user.events.map(e => (
                        <li className="list-group-item">
                            {getEventIcon(e)}
                            <span className="badge badge-primary">{e.start}</span> - <span className="badge badge-primary">{e.end}</span> {e.description}</li>
                    ))}
                </ul>
            </div>
        )
    } else {
        return (
            <div className="user-component user-component-empty">
                None
            </div>
        )
    }
}

export default UserAssignments;