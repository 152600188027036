import React, { useState, useEffect } from 'react';
import "./CapacityGrid.css"
import classNames from 'classnames'
import moment from "moment"

function CapacityGrid({api, role = 'pm'}) {
    const DATE_PATTERN = "YYYY-MM-DD"
    const days = 40
    const maxUsersInBucket = 6
    const roles = ["pm", "ba", "sa"]
    const maxScale = maxUsersInBucket * roles.length
   
    const [userNames, setUserNames] = useState([])
   
    const [capacityInfo, setCapacityInfo] = useState(null)

    useEffect(() => {
        api.getCapacityAll(days).then(capacityInfo => {
            const userNames = capacityInfo.users.reduce((prev, curr) => {prev[curr.id] = curr.caption; return prev}, {})
            setUserNames(userNames)
            setCapacityInfo(capacityInfo)
        })
    }, [])

    const getUserIcon = (userId) => {
        const userName = userNames[userId] || userId
        const [first, last] = userName.split(" ")
        return <div className="capacity-tooltip">{first[0] + last[0]}<span className="tooltiptext">{userName}</span></div>
    }

    const getUserCell = (dayIdx, capacityIdx) => {
        if (capacityIdx == -1) {
            const dayInfo = capacityInfo["pm"].days.find(ci => ci.dayIdx == dayIdx)
            const date = moment(dayInfo.date, DATE_PATTERN)
            const prevDay = capacityInfo["pm"].days.find(ci => ci.dayIdx == dayIdx - 1)
            const prevDate = prevDay ? moment(prevDay.date, DATE_PATTERN) : null
            let format = "MMM DD"
            if (prevDate && prevDate.month() === date.month()) {
                format = "DD"
            }
            const classes = {"capacity-date": true}
            if (date.isoWeekday() === 1) {
                classes["capacity-date-monday"] = true
            }
            return <td className={classNames(classes)}>{date.format(format)}</td>
        }
        const availableUsersAllRoles = roles.map(role => {
            const dayInfo = capacityInfo[role].days.find(ci => ci.dayIdx == dayIdx)
            const {availableUsers} = dayInfo
            availableUsers.sort()
            return availableUsers
        })
        const usersToDisplayPerRole = availableUsersAllRoles.map(userArray => userArray.slice(0, maxUsersInBucket))
        const usersToDisplay = usersToDisplayPerRole.flat()
        let counter = capacityIdx
        let currRoleIdx = 0
        for (; currRoleIdx < roles.length && counter >= 0; currRoleIdx++) {
            counter -= usersToDisplayPerRole[currRoleIdx].length
        }
        currRoleIdx -= 1
        const currentRole = roles[currRoleIdx]
        const cellClasses = {"capacity-cell": true}
        if (capacityIdx >= usersToDisplay.length) {
            cellClasses["capacity-empty"] = true
            return <td className={classNames(cellClasses)}>-</td>    
        } else {
            cellClasses["capacity-" + currentRole] = true
            const diff = availableUsersAllRoles[currRoleIdx].length - usersToDisplayPerRole[currRoleIdx].length
            if (counter == -1 && diff > 1) {
                const names = availableUsersAllRoles[currRoleIdx].slice(usersToDisplayPerRole[currRoleIdx].length).map(userId => userNames[userId])
                return <td className={classNames(cellClasses)}>
                    <div className="capacity-tooltip">+{diff}
                    <span className="tooltiptext">{names.join(", ")}</span>
                    </div>
                </td>
            } else {
                return <td className={classNames(cellClasses)}>{getUserIcon(usersToDisplay[capacityIdx])}</td>
            }
        }
    }

    return <div className="capacity-grid-container">
    {capacityInfo  && <table className="capacity-grid">
    {Array.from({length: maxScale + 1}, (el, index) => maxScale - index - 1).map((availableCapacity) => {
        return <tr>
        {Array.from({length: days}, (el, index) => index).map((dayIdx) => {
            return getUserCell(dayIdx, availableCapacity)
        })}
        </tr>
    })}
    </table>}
    </div>
}

export default CapacityGrid