import NavbarCommon from './NavbarCommon'
import CalendarGantt from './CalendarGantt'
import React, { useEffect, useState } from 'react';

function CalendarView({ api, signOut }) {

    const [workload, setWorkload] = useState(null)
    const [personalSettings, setPersonalSettings] = useState(null)

    const [myTeamOnly, setMyTeamOnly] = useState(false)
    const [allBAs, setAllBAs] = useState(false)
    const [allSAs, setAllSAs] = useState(false)

    const [myTeam, setMyTeam] = useState(null)

    useEffect(() => {
        if (workload === null) {
            const promises = Promise.all([api.getPersonalSetting(), api.getDeals()])
            promises.then(([personalSettings, workload]) => {
                if (personalSettings.calendarMyTeamOnly) {
                    setMyTeam(personalSettings.myTeam)
                } else {
                    setMyTeam(null)
                }
                setWorkload(workload)
                //TODO the following code should be replaced with a list of "my team" managable via user settings
                setMyTeamOnly(personalSettings.calendarMyTeamOnly)
                setAllBAs(personalSettings.calendarAllBAs)
                setAllSAs(personalSettings.calendarAllSAs)
                setPersonalSettings(personalSettings)
            }).catch((e) => {
                console.error(e)
            })
        }
    })

    useEffect(() => {
        if (personalSettings) {
            if (personalSettings.calendarMyTeamOnly != myTeamOnly || personalSettings.calendarAllBAs != allBAs || personalSettings.calendarAllSAs != allSAs) {
                const newSettings = Object.assign({}, personalSettings)
                newSettings.calendarMyTeamOnly = myTeamOnly
                newSettings.calendarAllBAs = allBAs
                newSettings.calendarAllSAs = allSAs
                api.setPersonalSettings(newSettings)
                setPersonalSettings(newSettings)
            }
            if (myTeamOnly) {
                setMyTeam(personalSettings.myTeam)
            } else {
                setMyTeam(null)
            }
        }
    }, [myTeamOnly, allBAs, allSAs])

    return (
        <div className="App container-fluid">
            <header>
                <NavbarCommon caption="Planning" signOut={signOut} userName={workload ? workload.userName : "N/A"} />
            </header>
            <section className="text-center">
                <form class="form-inline">
                    <div class="form-check mb-2 mr-sm-2">
                        <input class="form-check-input" type="checkbox" id="onlyMyGroup" checked={myTeamOnly} onChange={(e) => {setMyTeamOnly(e.target.checked)}}/>
                        <label class="form-check-label" for="onlyMyGroup">
                            Only my group
                        </label>
                    </div>
                    {myTeamOnly && <><div class="form-check mb-2 mr-sm-2">
                        <input class="form-check-input" type="checkbox" id="allBAs" checked={allBAs} onChange={(e) => {setAllBAs(e.target.checked)}}/>
                        <label class="form-check-label" for="allBAs">
                            All BAs
                        </label>
                    </div>
                    <div class="form-check mb-2 mr-sm-2">
                        <input class="form-check-input" type="checkbox" id="allSAs" checked={allSAs} onChange={(e) => {setAllSAs(e.target.checked)}}/>
                        <label class="form-check-label" for="allSAs">
                            All SAs
                        </label>
                    </div></>}
                </form>

                {workload && <CalendarGantt workload={workload} onlyUsers={myTeam} allBAs={allBAs} allSAs={allSAs} />}
            </section>
        </div>
    )
}

export default CalendarView;