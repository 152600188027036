import React, { useEffect, useState } from 'react';
import Deal from './Deal'
import Pool from './Pool'
import Octicon from 'react-octicon'

import Navbar from './Navbar'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import './WorkloadView.css';
import UserAssignments from './UserAssignments'
import DealDetails from './DealDetails'
import FilterSelector from './FilterSelector'
import AddDealSelector from './AddDealSelector'
import {useSelector, useDispatch } from 'react-redux'
import {setDealTabInProgress, setDealTabIntake, setLoadingTrue, setLoadingFalse, SET_SELECTED_DEAL, SET_WORKLOAD, setDealTabInitiatives} from '../redux/store'
 
function WorkloadView({api, signOut}) {
  const dispatch = useDispatch()
  const selectedUserId = useSelector(state => state.selectedUserId)
  const selectedDealId = useSelector(state => state.selectedDealId)  
  const data = useSelector(state => state.data)
  const filteredDeals = useSelector(state => state.data.filteredDeals)
  const dealTab = useSelector(state => state.dealTab)
  const [loaded, setLoaded] = useState(false) //shows that component was initially loaded
  const [showMessage, setShowMessage] = useState(true)

  function refresh(switchToIntake = false) {
    if (switchToIntake) {
      dispatch(setDealTabIntake)  
    }
    dispatch(setLoadingTrue)
    return api.getDeals().then((workload) => {
      dispatch({type: SET_WORKLOAD, value: workload})
    }).catch((e) => {
      console.error(e)
      alert("Error processing API request. Open console (F12). Make a screeshot and report a bug.")
      dispatch(setLoadingFalse)
    })
  }

  useEffect(() => {
    if (loaded) {
      return
    }
    setLoaded(true)
    refresh()
  })

  return (
    
    <div className="App">
      <header>
        <Navbar data={data} refresh={refresh} signOut={signOut} api={api}></Navbar>
      </header>
      {showMessage && data.globalMessage && 
      <div class="alert alert-warning alert-dismissible fade show" role="alert">
        {data.globalMessage}
        <button onClick={() => setShowMessage(false)} type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>        
      </div>
      }
      <DndProvider backend={HTML5Backend}><main role="main">
        <div className="row">
            <div className="col-4 deals">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <a className={dealTab === 'pipeline' ? 'nav-link active' : 'nav-link'} href="#" role="tab" onClick={() => dispatch(setDealTabIntake)}>
                  <Octicon name="primitive-square"/>
                  Pipeline
                </a>
              </li>
              <li className="nav-item">
                <a className={dealTab === 'in_progress' ? 'nav-link active' : 'nav-link'} href="#" role="tab" onClick={() => dispatch(setDealTabInProgress)}>
                <Octicon name="triangle-right"/>
                  In progress
                </a>
              </li>
              <li className="nav-item">
                <a className={dealTab === 'initiatives' ? 'nav-link active' : 'nav-link'} href="#" role="tab" onClick={() => dispatch(setDealTabInitiatives)}>
                <Octicon name="star"/>
                  Initiatives
                </a>
              </li>
              <li className="nav-item">
                <FilterSelector api={api}/>
              </li>
              <li className="nav-item">
                <AddDealSelector refresh={refresh} api={api} newDealTypes={data.newDealTypes} regions={data.regions}/>
              </li>
            </ul>
            <div className="tab-content">
              <div className={dealTab === 'in_progress' ? 'deals-wrapper tab-pane fade show active' : 'deals-wrapper tab-pane'}>
                {filteredDeals.filter(d => d.pipeline === 'in_progress' || d.pipeline === 'on_hold').map((deal, i) => (
                  <Deal api={api} tags={data.tags} users={data.users} key={i} deal={deal} 
                    selected={deal.id === selectedDealId} 
                    onClick={() => dispatch({type: SET_SELECTED_DEAL, value: deal.id })}
                    refresh={refresh}
                  />
                ))}
              </div>
              <div className={dealTab === 'pipeline' ? 'deals-wrapper tab-pane fade show active' : 'deals-wrapper tab-pane'}>
                {filteredDeals.filter(d => d.pipeline === 'intake').map((deal, i) => (
                  <Deal api={api} tags={data.tags} users={data.users} key={i} deal={deal} selected={deal.id === selectedDealId} 
                  onClick={() => dispatch({type: SET_SELECTED_DEAL, value: deal.id })}
                    refresh={refresh}
                  />
                ))}
              </div>
              <div className={dealTab === 'initiatives' ? 'deals-wrapper tab-pane fade show active' : 'deals-wrapper tab-pane'}>
                {filteredDeals.filter(d => d.pipeline === 'initiative_with_deadline' || d.pipeline === 'initiative_no_deadline').map((deal, i) => (
                  <Deal api={api} tags={data.tags} users={data.users} key={i} deal={deal} selected={deal.id === selectedDealId} 
                  onClick={() => dispatch({type: SET_SELECTED_DEAL, value: deal.id })}
                    refresh={refresh}
                  />
                ))}
              </div>

            </div>
        </div>
          <div className="col-8">
            <Pool pool={data.pool}></Pool>
            
            <div className="row">
              <div className="col-sm-6">
                <div className="selected-user">
                  Selected user:
                  <UserAssignments userId={selectedUserId}></UserAssignments>
                </div>

              </div>
              <div className="col-sm-6">
                  <div className="selected-deal">
                  Selected deal:
                  <DealDetails api={api} refresh={refresh} dealId={selectedDealId} tags={data.tags}></DealDetails>
                </div>

              </div>
            </div>
          </div>
        </div>
      </main></DndProvider>
    </div>
  );
}

export default WorkloadView;
