import React, { useRef, useState, useEffect } from 'react';
import {useSelector, useDispatch } from 'react-redux'
import { BsFileEarmarkPlus } from 'react-icons/bs';
import Dialog from "./Dialog";
import DatePicker from "react-datepicker";
import {executeApi} from './../utils/utils'

function AddDealSelector({api, refresh, newDealTypes, regions}) {

    const [dropShow, setDropShow] = useState(false)
    const [showDealEditDlg, setShowDealEditDlg] = useState(false)

    const [dlgDueDate, setDlgDueDate] = useState(new Date())
    const [dlgSummary, setDlgSummary] = useState(null)
    const [dlgCustomer, setDlgCustomer] = useState(null)
    const [dlgLocation, setDlgLocation] = useState([])
    const [dlgErrorMessage, setErrorMessage] = useState("")
    const [dlgDealType, setDlgDealType] = useState(null)
    const [createEpic, setCreateEpic] = useState(false)
  
    const filter = useSelector(state => state.filter)
    const dispatch = useDispatch()
    const ref = useRef(this);

    function showCreateDealDlg(typeName) {
        setDlgSummary("New " + typeName)
        setShowDealEditDlg(true)
        setDlgDealType(typeName)
        setDropShow(false)
        setCreateEpic(typeName === "RFP" || typeName === "RFI")
    }

    function createDeal() {
        if (! dlgSummary) {
            setErrorMessage("Summary is required");
            return;
        }
        if (dlgLocation.length === 0) {
            setErrorMessage("Location is required");
            return;
        }
        if (! dlgCustomer) {
            setErrorMessage("Customer is required");
            return;
        }

        function internalRefresh() {
            return refresh(true)
        }
        executeApi(true, dispatch, internalRefresh, () => {
            return api.createDeal(dlgDealType, {caption: dlgSummary, dueDate: dlgDueDate, location: dlgLocation, customer: dlgCustomer}).then(() => {
                setShowDealEditDlg(false)
            })
        })
    }

    useEffect(() => {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setDropShow(false)
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    function onChangeLocation(e) {
        let options = e.target.options;
        let value = [];
        for (let i = 0, l = options.length; i < l; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        console.log(value);
        setDlgLocation(value);
      }

    return (
        <>
            <div ref={ref} class="btn-group" role="group">
                <button type="button" className="nav-link btn dropdown-toggle"  onClick={() => setDropShow(! dropShow)}>
                    <BsFileEarmarkPlus/>
                    
                </button>
                <div className={dropShow ? "dropdown-menu show" : "dropdown-menu"}>
                    {newDealTypes.map(typeName => {
                            return <a onClick={() => {showCreateDealDlg(typeName)}} className="dropdown-item" href="#">{typeName}</a> 
                        }
                    )}
                </div>
            </div>
            <Dialog title={dlgDealType} errorMessage={dlgErrorMessage} saveCaption={"Create " + dlgDealType} show={showDealEditDlg} onClose={() => setShowDealEditDlg(false)} onSave={createDeal}>
                <form>
                    <div className="form-group">
                        <label>Deal summary &nbsp;</label>
                        <input className="form-control" type="text" value={dlgSummary} onChange={(e) => setDlgSummary(e.target.value)}/>
                    </div>
                    {createEpic && <div className="form-group">
                        <label>(!) New EPIC will be created</label>
                    </div>}
                    <div className="form-group">
                        <label>Location:</label>
                        <select className="form-control" onChange={onChangeLocation} multiple>
                            {regions.map(region => {
                                return <option value={region}>{region}</option>
                            })}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Customer</label>
                        <input className="form-control" type="text" value={dlgCustomer} onChange={(e) => setDlgCustomer(e.target.value)}/>
                    </div>
                    <div class="form-group">
                        <label>Due date &nbsp;</label>
                        <DatePicker className="form-control" selected={dlgDueDate} onChange={(date) => {setDlgDueDate(date)}}/>
                    </div>
                </form>
          </Dialog>            
        </>
    )


}

export default AddDealSelector;