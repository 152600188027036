import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux'
import { useDrop } from 'react-dnd'
import Octicon from "react-octicon"
import classNames from 'classnames'
import {executeApi} from './../utils/utils'

function DealAssignDropTarget({dealId, role, api, writable, refresh}) {
    
    const dispatch = useDispatch()

    const [{ isOver, isOverCurrent }, drop] = useDrop({
		accept: 'user',
        drop: (item, monitor) => {
            const didDrop = monitor.didDrop();
            if (didDrop) {
                return
            }
            executeApi(writable, dispatch, refresh, () => {
                return api.assignDealToUser(dealId, item.user_id, role)
            })
        },
		collect: monitor => ({
			isOver: monitor.isOver(),
            isOverCurrent: monitor.isOver({ shallow: true }),
		}),
    })

    return (
        <div className={classNames({"alert": true, "alert-primary": ! isOver, "alert-success": isOver})} ref={drop} role="alert">
            <Octicon mega className="person-icon" name="person"/>
            New Reviewer
        </div>
    )
}


export default DealAssignDropTarget;