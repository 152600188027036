import React, { useEffect, useState } from "react";
import {
    msalApp,
    requiresInteraction
} from "./../auth/authUtils";
import authConfig from "./../auth/authConfig";

const useRedirectFlow = true

function AuthProvider(props) {
    const [state, setStateInternal] = useState({
                account: null,
                emailMessages: null,
                graphProfile: null
            })

    const [error, setError] = useState(null)

    const setState = (state) => {
        setStateInternal(state)
    }            

    const LOGIN_REQUEST = {scopes: [authConfig.clientId]}

    async function acquireToken(request, redirect) {
        //return msalApp.acquireTokenSilent(request).catch(error => {
        return msalApp.acquireTokenSilent(LOGIN_REQUEST).catch(error => {
            // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
            // due to consent or interaction required ONLY
            if (requiresInteraction(error.errorCode)) {
                return redirect
                    ? msalApp.acquireTokenRedirect(request)
                    : msalApp.acquireTokenPopup(request);
            } else {
                console.error('Non-interactive error:', error.errorCode)
            }
        });
    }
    
    async function onSignIn(redirect) {
        if (redirect) {
            return msalApp.loginRedirect(LOGIN_REQUEST);
        }

        const loginResponse = await msalApp
            .loginPopup(LOGIN_REQUEST)
            .catch(error => {
                setError(error.message)
                setState({account: null})
            });

        if (loginResponse) {
            setState({
                account: loginResponse.account,
                error: null
            });
        }
    }
    
    function onSignOut() {
        msalApp.logout();
    }
    
    async function getToken() {
        if (state.account) {
            const token = await acquireToken(
                LOGIN_REQUEST
            ).catch(error => {
                setError(error.message);
                setState({account: null})
            });
            return {accessToken: token.accessToken, idToken: token.idToken.rawIdToken}
        } else {
            return null
        }
    }

    useEffect(() => {
        async function getAccount() {
            msalApp.handleRedirectCallback(error => {
                if (error) {
                    const errorMessage = error.errorMessage ? error.errorMessage : "Unable to acquire access token.";
                    // setState works as long as navigateToLoginRequestUrl: false
                    setError(errorMessage);
                    setState({account: null})
                }
            });
    
            const account = msalApp.getAccount();
            setState({
                account
            });
        }
        getAccount()
    }, [state.account])

    return (props.children({
        account: state.account,
        error: error,
        getAuthToken: getToken,
        signIn: (redirect) => onSignIn(redirect == undefined ? useRedirectFlow : redirect),
        signOut: () => onSignOut()
    }))
}

export default AuthProvider;