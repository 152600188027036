import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Dialog from "./../Dialog";

function DealFlaggingDlg({ show, setShow, save, deal, followUpTypes }) {

    function getAutoSummary(originalSummary, type) {
        return `[${type}] ${originalSummary}`
    }

    const [operation, setOperation] = useState("flag")
    const [createFollowUp, setCreateFollowUp] = useState(false)
    const [keepReviewer, setKeepReviewer] = useState(false)
    const [keepPM, setKeepPM] = useState(false)
    const [keepBA, setKeepBA] = useState(false)
    const [keepSA, setKeepSA] = useState(false)
    const [createType, setCreateType] = useState(followUpTypes[0])
    const [createSummary, setCreateSummary] = useState(getAutoSummary(deal.caption, followUpTypes[0]))
    const [createDueDate, setCreateDueDate] = useState(new Date())

    const saveDisabled = createFollowUp ? (createSummary == "") : false

    useEffect(() => {
        setOperation("flag")
        setCreateSummary(getAutoSummary(deal.caption, followUpTypes[0]))
        setCreateFollowUp(false)
    }, [deal])

    let saveCaption
    if (operation == "flag") {
        saveCaption = "Flag deal"
    }
    if (operation == "delete") {
        saveCaption = "Delete round permanently"
    }

    function updateCreateType(newType) {
        if (createSummary == getAutoSummary(deal.caption, createType)) {
            setCreateSummary(getAutoSummary(deal.caption, newType))
        }
        setCreateType(newType)
    }

    function onSave() {
        save({
            operation,
            createFollowUp,
            createType,
            createSummary,
            createDueDate,
            createEpic: deal.epic,
            keepReviewer,
            keepPM,
            keepBA,
            keepSA
        })
    }

    function onRadioChange(event) {
        setOperation(event.target.value)
    }

    return <Dialog title='Confirm deal removal'
        saveCaption={saveCaption}
        saveBtnCls={operation == "delete" ? "btn-danger" : "btn-primary"}
        saveDisabled={saveDisabled}
        show={show}
        onClose={() => setShow(false)}
        onSave={onSave}>
        <form>
            <p>
                Deal: <strong>{deal.caption}</strong>
            </p>
            <div className="form-check">
                <input id="flagDealRadio" value="flag" onChange={onRadioChange} className="form-check-input" type="radio" name="flexRadioDefault" checked={operation == "flag"}/>
                <label className="form-check-label" htmlFor="flagDealRadio">
                    Flag deal
                </label>
            </div>
            {(! deal.isEpic) && deal.epic && operation == "flag" && <div className="form-check ml-3">
                <input type="checkbox" className="form-check-input" id="checkCreateFollowUp" checked={createFollowUp} onChange={e => setCreateFollowUp(e.currentTarget.checked)}/>
                <label className="form-check-label" for="checkCreateFollowUp">Create follow-up</label>
            </div>}
            {createFollowUp && deal.epic && operation == "flag" && <>
                <div className="form-group ml-3">
                    <label>Follow-up type &nbsp;</label>
                    <select className="form-control" value={createType} onChange={e => updateCreateType(e.target.value)}>
                        {followUpTypes.map((typeName, idx) => <option value={typeName}>{typeName}</option>)}
                        <option value="Tracking">Tracking</option>
                    </select>
                </div>
                <div className="form-group ml-3">
                    <label>Deal summary &nbsp;</label>
                    <input className="form-control" type="text" value={createSummary} onChange={e => setCreateSummary(e.target.value)}/>
                </div>
                
                <div className="form-group ml-3">
                    <label>Due date &nbsp;</label>
                    <DatePicker className="form-control" selected={createDueDate} onChange={date => {setCreateDueDate(date)}}/>
                </div>
                <div className="form-check ml-3">
                    <input type="checkbox" className="form-check-input" id="checkKeepReviewer" checked={keepReviewer} onChange={e => setKeepReviewer(e.currentTarget.checked)}/>
                    <label className="form-check-label" for="checkKeepReviewer">Keep reviewer</label>
                </div>
                <div className="form-check ml-3">
                    <input type="checkbox" className="form-check-input" id="checkKeepPM" checked={keepPM} onChange={e => setKeepPM(e.currentTarget.checked)}/>
                    <label className="form-check-label" for="checkKeepPM">Keep PM</label>
                </div>
                <div className="form-check ml-3">
                    <input type="checkbox" className="form-check-input" id="checkKeepBA" checked={keepBA} onChange={e => setKeepBA(e.currentTarget.checked)}/>
                    <label className="form-check-label" for="checkKeepBA">Keep BA</label>
                </div>
                <div className="form-check ml-3">
                    <input type="checkbox" className="form-check-input" id="checkKeepSA" checked={keepSA} onChange={e => setKeepSA(e.currentTarget.checked)}/>
                    <label className="form-check-label" for="checkKeepSA">Keep SA</label>
                </div>


            </>}
            {(! deal.isEpic) && <div className="form-check">
                <input id="deleteDealRadio" value="delete" onChange={onRadioChange} className="form-check-input" type="radio" name="flexRadioDefault"  checked={operation == "delete"} />
                <label className="form-check-label" htmlFor="deleteDealRadio">
                    Delete round
                </label>
            </div>}

        </form>
    </Dialog>

}

export default DealFlaggingDlg