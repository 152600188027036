import React, { useReducer } from 'react';
import moment from "moment"
import classNames from 'classnames'
import './CalendarGantt.css'

const DATE_PATTERN = "YYYY-MM-DD"

const isWeekend = (m) => {
    return m.day() === 0 || m.day() === 6
}

const generateWorkingDates = (start, count) => {
    const res = []
    let curr = start.clone()
    for (let i = 0; i < count; i++) {
        while(isWeekend(curr)) {
            curr.add(1, 'days');
        }
        res.push(curr)
        curr = curr.clone()
        curr.add(1, 'days');
    }
    return res
}

const getUsers = (workload, onlyUsers, allBAs, allSAs) => {
    if (! workload) {
        return []
    }
    return workload.users
    .filter((u) => {
        if (u.role == "other") {
            return false
        } else if (! onlyUsers) {
            return true
        } else if (allBAs && u.role == "ba") {
            return true
        } else if (allSAs && u.role == "sa") {
            return true
        } else {
            return onlyUsers.indexOf(u.id) != -1
        }
    })
    .sort((u1, u2) => {
        const roles = ["pm", "ba", "sa"]
        const r1 = roles.indexOf(u1.role)
        const r2 = roles.indexOf(u2.role)
        if (r1 === r2) {
            if (u1.caption === u2.caption) {
                return 0
            }
            return u1.caption > u2.caption ? 1 : -1
        } else {
            return r1 > r2 ? 1 : -1
        }
    })
}

const getVacs = (user, date) => {
    const vacs = user.events
        .filter(e => e.type === "vacation")
        .filter(e => {
            const d = moment(date)
            return d.isSameOrAfter(moment(e.start, DATE_PATTERN), "day") && d.isSameOrBefore(moment(e.end, DATE_PATTERN), "day")
        })
    return vacs
}

const getDeals = (user, date, workload) => {
    const deals = workload.deals
    .filter(d => {
        return d.pipeline === "in_progress"
    })
    .filter(d => {
        return d.team.find(a => a.user_id === user.id && a.role != "reviewer")
    })
    .filter(deal => {
        const d = moment(date)
        return d.isSameOrBefore(moment(deal.duedate, DATE_PATTERN))
    })
    return deals
}

const getReviewing = (user, date, workload) => {
    const deals = workload.deals
    .filter(d => {
        return d.pipeline === "in_progress"
    })
    .filter(d => {
        return d.team.find(a => a.user_id === user.id && a.role == "reviewer")
    })
    .filter(deal => {
        const d = moment(date)
        return d.isSameOrBefore(moment(deal.duedate, DATE_PATTERN))
    })
    return deals
}

const getVacsStr = (vacs) => {
    const res = []
    vacs.forEach(v => {
        res.push(`vac ${v.start} - ${v.end}`)
    })
    return res
}

const getDealsStr = (deals, prefix = "") => {
    const res = []
    deals.forEach(d => {
        res.push(`${prefix}${d.caption} (${d.duedate})`)
    })
    return res
}

const getMixedStr = (vacs, deals) => {
    return getVacsStr(vacs).concat(getDealsStr(deals))
}


function CalendarGantt({ workload, onlyUsers, allBAs, allSAs }) {
    const DATE_PATTERN = "YYYY-MM-DD"

    const today = moment(workload.today, DATE_PATTERN)

    const users = getUsers(workload, onlyUsers, allBAs, allSAs)

    const days = 40

    const dates = generateWorkingDates(today, days)

    return (
        <div className="calendar-gantt gantt-grid-container">
            <table className="gantt-grid">
                <tr className="calendar-gantt-days">
                    <td></td>
                {dates.map((date, idx) => {
                    let format
                    if (idx === 0 || date.date() === 1) {
                        format = "MMM DD"
                    } else {
                        format = "DD"
                    }
                    return <td>{date.format(format)}</td>
                })}
                </tr>

                {users.map((user, idx) => {
                    return <tr className="calendar-gantt-user-row">
                        <td className="calendar-gantt-user">{user.caption}</td>
                        {dates.map((date, idx) => {
                            const vacs = getVacs(user, date)
                            const deals = getDeals(user, date, workload)
                            const reviewing = getReviewing(user, date, workload)
                            const classes = {}
                            let tooltip = null
                            if (date.isoWeekday() === 1) {
                                classes["gantt-date-monday"] = true
                            }
                            if (vacs.length > 0 && (deals.length + reviewing.length) == 0) {
                                classes["gantt-vac"] = true
                                tooltip = getVacsStr(vacs)
                                //return <td>V</td>
                            } else if (vacs.length == 0 && deals.length == 0 && reviewing.length > 0) {
                                classes["gantt-reviewer"] = true
                                tooltip = getDealsStr(reviewing)
                            } else if (vacs.length == 0 && deals.length > 0) {
                                classes["gantt-work"] = true
                                tooltip = getDealsStr(deals).concat(getDealsStr(reviewing, "[review]"))
                                //return <td>W</td>
                            } else if (vacs.length > 0 && deals.length > 0) {
                                classes["gantt-mixed"] = true
                                tooltip = getMixedStr(vacs, deals)
                                //return <td>M</td>
                            }
                            if (tooltip) {
                                let totalDeals = deals.length + reviewing.length
                                if (totalDeals === 0) {
                                    totalDeals = "-"
                                }
                                return <td className={classNames(classes)}>
                                    <div className="gantt-tooltip">{totalDeals}
                                        <span className="tooltiptext">
                                        <ul>
                                            {tooltip.map(str => {
                                                return <li>{str}</li>
                                            })}
                                        </ul>
                                        
                                        </span>
                                    </div>
                                </td>
                            } else {
                                return <td className={classNames(classes)}>-</td>
                            }
                            
                            
                        })}                        
                    </tr>
                })}
                <tr className="calendar-gantt-days">
                    <td></td>
                {dates.map((date, idx) => {
                    let format
                    if (idx === 0 || date.date() === 1) {
                        format = "MMM DD"
                    } else {
                        format = "DD"
                    }
                    return <td>{date.format(format)}</td>
                })}
                </tr>
            </table>
        </div>
    )
}

export default CalendarGantt;