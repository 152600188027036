import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import MainView from './components/MainView';
import LoginView from './components/LoginView';
//import data from './data/epmdsps.json';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import api from './api'
import createAppStore from './redux/store'
//import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
//import Login from './components/Login';
import AuthProvider from './components/AuthProvider'

const store = createAppStore({
  deals: [], 
  filteredDeals: [],
  users: [], 
  systemInfo: {version: 'not initialized', jiraUrl: 'http://localhost'}, 
  changeInfo: {changes: [], changed: false, isSaving: false},
  pool: {
    available: [],
    busy: []
  },
  globalMessage: null,
  tags: [],
  newDealTypes: [],
  regions: []
})

ReactDOM.render(
  <Provider store={store}>
    <AuthProvider>{
      ({account, signIn, signOut, getAuthToken, error}) => {
        if (account === null) {
          if (error) {
            console.log(error)
          }
          return (<LoginView error={error} signIn={signIn}/>)
        } else {
          return (<MainView getAuthToken={getAuthToken} signIn={signIn} signOut={signOut} account={account}/>)
        }
      }
    }</AuthProvider>
    
  </Provider>, 
document.getElementById('root'))


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
