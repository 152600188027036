import React from "react";
import User from "./User"

import { useDispatch } from 'react-redux'
import {SET_SELECTED_USER} from './../redux/store'

function Pool({pool}) {
    const dispatch = useDispatch()
    const priority = ['pm', 'ba', 'sa'] //TODO into settings
    const rolesSet = new Set(Object.keys(pool.available).concat(Object.keys(pool.busy)))
    let roles = []
    priority
    .filter(role => rolesSet.has(role))
    .forEach(role => {
        roles.push(role)
    })
    priority.forEach(role => {
        rolesSet.delete(role)
    })
    roles = [...roles, ...rolesSet]

    return (
        <div>
            <div className="card-deck pool">
                {roles.filter(role => role != "other").map((role) => (
                        <div className="card" key={role}>
                            <div class="card-header">
                            <strong>{role}</strong> - available
                            </div>
                            <div className="collapse show">
                                <div className="card-body">
                                    {pool.available[role] && pool.available[role].sort().map((user_id) => (
                                        <User key={user_id} user_id={user_id} onClick={() => dispatch({type: SET_SELECTED_USER, value: user_id })}></User>
                                    ))}
                                </div>
                            </div>
                            <div class="card-header card-middle-header">
                                    assigned
                            </div>
                            <div className="collapse show">
                                <div className="card-body">
                                    {pool.busy[role] && pool.busy[role].sort().map((user_id) => (
                                        <User showBusy={true} key={user_id} user_id={user_id} onClick={() => dispatch({type: SET_SELECTED_USER, value: user_id })}></User>
                                    ))}
                                </div>
                            </div>

                        </div>
                ))}
            </div>
        </div>
        
    )
}

export default Pool