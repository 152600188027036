import React, { useState, useEffect } from "react";
import {useSelector, useDispatch } from 'react-redux'
import {setLoadingTrue} from './../redux/store'
import CommangLogDlg from "./CommandLogDlg"
import Menu from "./Menu"
import Dialog from "./Dialog"
import Octicon from 'react-octicon'
import {executeApi} from './../utils/utils'

//TODO refactor to a common Navbar
//TODO refactor: move KPI into component
function Navbar({data, refresh, signOut, api}) {
  const [showKpiDlg, setShowKpiDlg] = useState(false)
  const [kpi, setKpi] = useState(null)
  const [archiveQueue, setArchiveQueue] = useState(null)
  const [showCommandLogDlg, setShowCommandLogDlg] = useState(false)
  const [showSaveErrorDlg, setShowSaveErrorDlg] = useState(false)
  const [showQueuedCommandsDlg, setShowQueuedCommandsDlg] = useState(false)
  const [saveErrorMsg, setSaveErrorMsg] = useState(null)
  const [notSavedCmds, setNotSavedCmds] = useState([])
  
  function shortenName(name) {
    if (! name || ! name.split) {
      return name
    }
    const parts = name.split(" ")
    if (parts.length > 1) {
      return parts[0].substring(0, 1) + ". " + parts[1]
    } else {
      return name
    }
  }

  useEffect(() => {
    //TODO use setings to get year
    if (kpi === null) {
      api.getKpiReport().then(json => setKpi(json))
    }
    if (archiveQueue == null) {
      api.getArchiveReport().then((json) => {
        if (json && json.length) {
          const top3 = json.filter(i => i.assignee != null && i.count > 1).slice(0, 3)
          setArchiveQueue(top3)
        } else {
          setArchiveQueue([])
        }
      })
    }
  });

  const saveToJira = () => {
    executeApi(writable, dispatch, refresh, () => {
      return api.saveToJira()
    })
  }

  const undo = () => {
    executeApi(writable, dispatch, refresh, () => {
      return api.undo()
    })
  }

  const retrySave = () => {
    dispatch(setLoadingTrue);
    setShowSaveErrorDlg(false)
    api.addCommands(notSavedCmds).then(() => {
      refresh()
    })
  }

  const dispatch = useDispatch()
  const loading = useSelector(state => state.loading)
  const writable = useSelector(state => state.data.writable)
  const commandsQueue = useSelector(state => state && state.data && state.data.changeInfo && state.data.changeInfo.commandsQueue ? state.data.changeInfo.commandsQueue : [])

  return (
<div className="main-toolbar d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm" style={{height: "4em"}}>
  <h5 className="my-0 mr-3 font-weight-normal">
    <Menu signOut={signOut} userName={data.userName}/>
    Group workload &nbsp;
    {data.systemInfo.version &&
      <sub>v{data.systemInfo.version}</sub>
    }
    {data.systemInfo.jiraUrl.startsWith('https://preprod-jira-api.epam.com') &&
      <sub><a href="https://jira.epam.com/jira/projects/EPMDSPS/summary" target='_blank'>(pre-prod{! writable ? ' Read-Only' : ''})</a></sub>
    }
    {data.systemInfo.jiraUrl.startsWith('https://jira-api.epam.com') && data.systemInfo.project === 'EPMDSPSPLN' &&
      <sub><a href="https://jira.epam.com/jira/projects/EPMDSPSPLN/summary" target='_blank'>(Test{! writable ? ' Read-Only' : ''})</a></sub>
    }
    {data.systemInfo.jiraUrl.startsWith('https://jira-api.epam.com') && data.systemInfo.project === 'EPMDSPS' &&
      <sub><a href="https://jira-api.epam.com" target='_blank'>(PROD{! writable ? ' Read-Only' : ''})</a></sub>
    }
  </h5>
  <nav className="my-2 mt-auto mr-md-auto small">
    <ul className="breadcrumb py-1 px-1">
      <li><Octicon name="pulse"/>&nbsp;</li>
      <li>In progress: {kpi && (kpi.find(i => i._id === 'in_progress') || {count: '-'}).count} <span className="divider">/&nbsp;</span></li>
      <li>Pending: {kpi && (kpi.find(i => i._id === 'pending_customer') || {count: '-'}).count} <span className="divider">/&nbsp;</span></li>
      <li>No kpi: {kpi && (kpi.find(i => i._id === 'no_kpi') || {count: '-'}).count} <span className="divider">/&nbsp;</span></li>
      <li>Won: {kpi && (kpi.find(i => i._id === 'won') || {count: '-'}).count} <span className="divider">/&nbsp;</span></li>
      <li>Lost: {kpi && (kpi.find(i => i._id === 'lost') || {count: '-'}).count}</li>

      {archiveQueue && archiveQueue.length > 0 && <>
        <li><span className="divider">&nbsp;|&nbsp;</span><Octicon name="file-submodule"/>&nbsp;</li>
        <li>Arch queue:&nbsp;</li>
        {archiveQueue.map((q, idx) => {
          return <li key={"archive-queue-" + idx}>{shortenName(q.assignee)} <strong>{q.count}</strong> {(idx < archiveQueue.length - 1) && <span className="divider">/&nbsp;</span>}</li>
        })}
      </>}

      <li><a onClick={() => setShowKpiDlg(true)} href="#" style={{padding: '-'}} className="btn btn-sm"><Octicon name="triangle-down"/></a></li>

    </ul>
    
    <CommangLogDlg show={showCommandLogDlg} onClose={() => setShowCommandLogDlg(false)} api={api}/>

    <Dialog title='Current stats' show={showKpiDlg} onClose={() => setShowKpiDlg(false)} saveCaption={null} width="modal-xl" scrollable={true}>
      {["won", "lost", "no_kpi", "pending_customer", "in_progress"].map(category => {
        const categoryData = (kpi || []).find(i => i._id === category)
        const catDeals = categoryData ? categoryData.keys : []
        return <div key={'tbl' + category}>
          <table>
            <thead>
              <tr>
                  <th colSpan="2">{category}</th>
              </tr>
              <tr>
                <th>key</th><th>summary</th>
              </tr>
            </thead>
            <tbody>
              {catDeals.map(d => {
                return <tr key={d.key}>
                  <td>{d.key}</td>
                  <td>{d.summary}</td>
                </tr>
              })}
            </tbody>
          </table>
          <hr/>
        </div>
      })}
    </Dialog>

  </nav>
    {loading &&
      <div className="d-flex align-items-center">
        <strong>Loading...</strong>
        <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
      </div>
    }

    {data.changeInfo.changed > 0 && (! loading ) &&
      <React.Fragment>
        <a onClick={() => undo()} className="btn btn-warning" href="#">Undo</a>
        &nbsp;
        <a onClick={() => {saveToJira()}} className="btn btn-primary" href="#">Save to jira ({data.changeInfo.changes})</a>
        &nbsp;
        &nbsp;
      </React.Fragment>
    }
    {(! (loading)) &&
      <a onClick={() => refresh()} className="btn btn-outline-primary" href="#">Update</a>
    }
    &nbsp;<a onClick={() => {setShowCommandLogDlg(true)}} className="btn btn-outline-primary" href="#">...</a>
    <Dialog title='Error saving data to jira' show={showSaveErrorDlg} onClose={() => setShowSaveErrorDlg(false)} onSave={retrySave} saveCaption='Apply changes again'>
        <p>There was an error saving data to JIRA.</p>
        <p>System error message: {saveErrorMsg}</p>
        <p>The following information was not saved:</p>
        <ul>
          {notSavedCmds.map(cData => (
            <li>{cData.type}</li>
          ))}
        </ul>

    </Dialog>

    <Dialog title='Commands to be saved' show={showQueuedCommandsDlg} onClose={() => setShowQueuedCommandsDlg(false)}>
        <p>The following actions to be saved ({commandsQueue.length}):</p>
        <table className="commands-queue">
            <thead>
              <tr>
                <th>Author</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {commandsQueue.map(c => {
                const getCmdDescr = (c) => {
                  switch(c.type) {
                    case "AssignCmd":
                      return `Assign ${c.dealId} to ${c.userId} as ${c.role}`
                    case "RemoveAssignCmd":
                        return `Remove ${c.userId} as ${c.role} from ${c.dealId}`
                    case "SetFieldsCmd":
                        return `Change ${c.dealId} details`
                    case "FlagDealCmd":
                        return `Flag ${c.dealId}`
                    default: 
                        return c.type
                  }
                }

                return <tr key={c.id}>
                  <td>{c.author}</td>
                  <td>{getCmdDescr(c)}</td>
                </tr>
              })}
            </tbody>
          </table>        
    </Dialog>    

</div>
);
}
export default Navbar;

