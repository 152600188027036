import React, { useState, useEffect } from "react";
import Menu from "./Menu"

function NavbarCommon({caption, signOut, userName}) {
  
  return (
<div className="main-toolbar d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm" style={{height: "4em"}}>
  <h5 className="my-0 mr-3 font-weight-normal">
    <Menu signOut={signOut} userName={userName}/>
    {caption} &nbsp;
  </h5>
</div>
);
}
export default NavbarCommon;

