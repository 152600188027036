import { SET_LOADING } from "../redux/store";

const roleOrder = ["pm", "sa", "ba", "reviewer"]
export function sortTeamByAssignee(deal) {
    return deal.team.slice().sort((a, b) => {
        if (a.user_id == b.user_id) {
            const aOrder = roleOrder.indexOf(a.role)
            const bOrder = roleOrder.indexOf(b.role)
            return aOrder == bOrder ? 0 : aOrder > bOrder ? 1 : -1
        }
        if (a.user_id == deal.assignee) {
            return -1
        }
        if (b.user_id == deal.assignee) {
            return 1
        }
        if (a.user_id == deal.reviewer) {
            return 1
        }
        if (b.user_id == deal.reviewer) {
            return -1
        }

        return a.user_id > b.user_id ? 1 : -1
    });
}

let executingApi = false

export function executeApi(writable, dispatch, refresh, fn) {
    if (! writable) {
        return
    }
    if (executingApi) {
        alert("Still working on a previous operation.")
        return
    }
    dispatch({type: SET_LOADING, value: true}); 
    try {
        executingApi = true
        fn().then(() => {
            refresh().then(() => {
                executingApi = false
            })
        }).catch((e) => {
            executingApi = false
            console.error(e)
            alert("Error executing api command. Please open console (F12), make a screeshot and report a bug.")
        })
    } catch(e) {
        executingApi = false
        console.error(e)
        alert("Error executing api command (2). Please open console (F12), make a screeshot and report a bug.")
    }
}

