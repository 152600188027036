import React from "react"
import User from "./User"
import JointEffortIndicator from "./JointEffortIndicator"
import DealAssignDropTarget from "./DealAssignDropTarget";
import { useDrop } from 'react-dnd'
import { useDispatch } from 'react-redux'
import {SET_SELECTED_USER, SET_SELECTED_DEAL} from './../redux/store'
import { useSelector } from 'react-redux'
import Octicon from 'react-octicon'
import "./Deal.css"
import {sortTeamByAssignee, executeApi} from './../utils/utils'

function Deal({deal, refresh, selected, idPrefix='deal', onClick = function() {}, tags, api}) {
  
  const writable = useSelector(state => state.data.writable)
  const dispatch = useDispatch()

  const [{ isOver }, drop] = useDrop({
		accept: 'user',
		drop: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
          return
      }
      executeApi(writable, dispatch, refresh, () => {
          return api.assignDealToUser(deal.id, item.user_id, item.role)
      })
    },
		collect: monitor => ({
			isOver: !!monitor.isOver(),
		}),
  })

  return (
    <div className={`card ${selected ? "deal-selected" : ""} ${isOver && writable ? 'deal-over' : ''}`} id={`${idPrefix}-${deal.id}`} onClick={onClick} ref={drop}>
      <div className="card-header">
      <span alt="Due date" className="badge badge-primary">{deal.duedate ? deal.duedate : 'No Date'}</span>
      &nbsp;
      {deal && deal.tags && deal.tags.map(tag => {
            const tInfo = tags.find(t => t.name == tag)
            return (
              <>
                <span alt={tag} className={"badge " + (tInfo.cls || "badge-primary")}>{tInfo.caption}</span>
                &nbsp;
              </>
            )
      })}
     
      {deal.caption}
      {deal.comment && <div className="comment-icon"><Octicon title={deal.comment} name="comment"/></div>}
      </div>
      <div className="card-body">
        <div className="team">
          {sortTeamByAssignee(deal)
          .filter(assignment => ! (deal.reviewer && deal.reviewer === deal.assignee && deal.reviewer === assignment.user_id && assignment.role === "reviewer"))
          .reduce((prev, curr) => {
            if (prev.indexOf(curr.user_id) === -1) {
              prev.push(curr.user_id)
            }
            return prev
          }, [])
          .map((user_id, i) => (
            <User key={i} 
              leader={deal.assignee === user_id}
              reviewer={deal.reviewer === user_id}
              showVacation={false} user_id={user_id} 
              onClick={() => {
                dispatch({type: SET_SELECTED_USER, value: user_id }); 
                dispatch({type: SET_SELECTED_DEAL, value: deal.id })}}
              ></User>
          ))}
          {isOver && writable && <DealAssignDropTarget dealId={deal.id} role="reviewer" api={api} writable={writable} refresh={refresh}/>}          
        </div>
        {
          deal.isEpic && (
          <div className="warnings-section">
            <hr/>
            <p><small><Octicon name="alert"/> Rounds must be created for a new Opportunity</small></p>
          </div>
          )
        }
      </div>
    </div>
  );
}
export default Deal;