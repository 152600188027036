import React, { useState, useEffect, useRef } from "react"
import Octicon from 'react-octicon'
import {
    Link
} from "react-router-dom";

function Menu({signOut, userName}) {
    const [opened, setOpened] = useState(false)
    const ref = useRef(this);

    useEffect(() => { //TODO refactor code duplicate
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setOpened(false)
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return (
        <div ref={ref} className="main-menu dropdown">
            <button onClick={() => setOpened( ! opened)} className="btn btn-sm btn-info dropdown-toggle" type="button">
                <Octicon name="grabber"/>
            </button>
            <div className={opened ? "dropdown-menu show" : "dropdown-menu"}>
                <Link className="dropdown-item" to="/reports"><Octicon name="graph"/> Reporting</Link>
                <Link className="dropdown-item" to="/"><Octicon name="dashboard"/> Workload</Link>
                <Link className="dropdown-item" to="/calendar"><Octicon name="calendar"/> Calendar</Link>
                <a className="dropdown-item" target="_blank" href="/client/archiving"><Octicon name="file-submodule"/> Archive wizard</a>
                <a className="dropdown-item" target="_blank" href="/client/calendar"><Octicon name="calendar"/> Planning</a>
                <a className="dropdown-item" target="_blank" href="/client/"><Octicon name="graph"/> Housekeeping (beta)</a>
                <div class="dropdown-divider"></div>
                <a onClick={signOut} className="dropdown-item" href="#"><Octicon name="sign-out"/> Sign Out {userName}</a>
            </div>                
        </div>
    )
}

export default Menu;