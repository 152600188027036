import React, { useState, useEffect } from 'react';
import NavbarCommon from './NavbarCommon'
import CapacityGrid from './CapacityGrid'

function PlanningView({ api, signOut }) {


    return (
        <div className="App container-fluid">
            <header>
                <NavbarCommon caption="Planning" signOut={signOut} />
            </header>
            <section className="text-center">
                <CapacityGrid api={api} />
            </section>
        </div>
    )
}

export default PlanningView;