import React, { useState, useEffect } from 'react';
import NavbarCommon from './NavbarCommon'
import Dialog from "./Dialog"
import DatePicker from "react-datepicker";
const Moment = require('moment-timezone')

const MOMENT_DATE_PATTERN = 'YYYY-MM-DD'

function ReportingView({ api, signOut }) {
    const [reports, setReports] = useState([])
    const [working, setWorking] = useState(false)
    const [showHtmlReportDlg, setShowHtmlReportDlg] = useState(false)
    const [reportHtml, setReportHtml] = useState("")
    const [currentReportCaption, setCurrentReportCaption] = useState("")

    const [showReportParamsDlg, setShowReportParamsDlg] = useState(false)
    const [paramsDlgReportName, setParamsDlgReportName] = useState("")
    const [sinceDateParam, setSinceDateParam] = useState(null)

    useEffect(() => {
        api.getReports().then(setReports)
    }, [])

    function promptReport(name) {
        if (name == "group_log") {
            setParamsDlgReportName("group_log")
            setShowReportParamsDlg(true)
        } else {
            generateReport(name)
        }
    }

    function generateReportWithParameters() {
        const sinceDate = sinceDateParam ? Moment(sinceDateParam).format(MOMENT_DATE_PATTERN) : null
        const params = {
            sinceDate
        }
        setShowReportParamsDlg(false)
        generateReport(paramsDlgReportName, params)
    }

    const generateReport = async (name, params) => {
        const report = reports.find(r => r.name === name)
        if (report.type === "xls") {
            setWorking(true)
            api.generateReport_xls(name, {}).then(() => {
                setWorking(false)
            })
            .catch(e => {
                setWorking(false)
            })
        } else {
            setWorking(true)
            const paramsToSend = report.params.filter(param => param.default).reduce((prev, curr) => {prev[curr.name] = prev[curr.name] || curr.default; return prev}, params)
            try {
                const html = await api.generateReport_html(name, paramsToSend)
                setCurrentReportCaption(report.caption)
                setReportHtml(html)
                setShowHtmlReportDlg(true)
            } finally {
                setWorking(false)
            }
        }
    }

    return (
        <div className="App container-fluid">
            <header>
                <NavbarCommon caption="Reporting" signOut={signOut} userName="" />
            </header>
            
                {working && 
                <section className="text-center">
                    <p>
                        <strong>Generating report...</strong>
                        <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                    </p>
                </section>}
                {(!working) && <div className="row"><div className="ml-1 ml-1 mb-3 card-deck text-center">
                    {reports.map(r => 
                        <div key={r.name} className="card mb-4 shadow-sm">
                            <div className="card-header">
                                <h4 className="my-0 font-weight-normal">{r.caption}</h4>
                            </div>
                            <div className="card-body">
                                <p>{r.description}</p>
                                <button onClick={() => promptReport(r.name)} type="button" className="btn btn-lg btn-block btn-outline-primary">Generate</button>
                            </div>
                        </div>                        
                    )}
                </div></div>}
            
            <Dialog title={currentReportCaption} show={showHtmlReportDlg} onClose={() => setShowHtmlReportDlg(false)} saveCaption={null} width="modal-xl" scrollable={true}>
                <div dangerouslySetInnerHTML={{ __html: reportHtml }} />
            </Dialog>            

            <Dialog title="Report parameters" saveCaption="Generate report" show={showReportParamsDlg} onClose={() => setShowReportParamsDlg(false)} onSave={generateReportWithParameters}>
                <form>
                <div class="form-group">
                    <label>Since date &nbsp;</label>
                    <DatePicker className="form-control" selected={sinceDateParam} onChange={(date) => {setSinceDateParam(date)}}/>
                </div>
                </form>
            </Dialog>            
        </div>
    )
}

export default ReportingView;