import { createStore } from "redux"
import moment from "moment"

const DATE_PATTERN = "YYYY-MM-DD"



const createAppStore = (initialData) => {
    const dataReducer = (state = {
        data: initialData,
        dealTab: "in_progress",
        filter: null,
        regionsFilter: [],
        includeBlankRegion: false,        
        selectedUserId: null,
        selectedDealId: null,
        loading: false,
      }, action) => {
        switch (action.type) {
            case SET_DEAL_TAB:
                return {...state, dealTab: action.value}
            case SET_FILTER: {
                const newState =  {...state, filter: action.value}
                let myTeam = action.myTeam || state.data.myTeam || []
                myTeam = myTeam.map(u => normalizeUserId(u))
                newState.data.myTeam = myTeam
                newState.data.filteredDeals = filterDeals(newState)
                return newState
            }
            case CLEAR_ALL_FILTERS: {            
                const newState =  {...state, filter: null, regionsFilter: []}
                newState.data.filteredDeals = filterDeals(newState)
                return newState
            }
            case TOGGLE_REGION_FILTER: {
                const regions = state.regionsFilter.concat()
                const idx = regions.indexOf(action.value)
                if (idx == -1) {
                    regions.push(action.value)
                } else {
                    regions.splice(idx, 1)
                }
                const newState =  {...state, regionsFilter: regions}
                newState.data.filteredDeals = filterDeals(newState)
                return newState
            }
            case TOGGLE_INCLUDE_BLANK_REGION_FILTER: {
                const newValue = ! state.includeBlankRegion
                const newState =  {...state, includeBlankRegion: newValue}
                newState.data.filteredDeals = filterDeals(newState)
                return newState                
            }
            case SET_SELECTED_USER:
                return {...state, selectedUserId: action.value}
            case SET_SELECTED_USER_AND_THEIR_DEAL:
                let selectedDealId = state.selectedDealId
                let selectedUserId = action.value
                let user = state.users.find(u => u.id === selectedUserId)
                if (user && user.assignedTo && user.assignedTo.length) {
                    selectedDealId = user.assignedTo[0]
                }
                return {...state, selectedUserId, selectedDealId}
            case SET_SELECTED_DEAL:
                return {...state, selectedDealId: action.value}
            case SET_LOADING:
                return {...state, loading: action.value}
            case SET_WORKLOAD:
                const myTeam = state.data.myTeam;
                action.value.myTeam = myTeam;
                const newState = {...state, data: action.value, loading: false}
                if (newState && newState.data && newState.data.users && ! newState.data.users.find(u => u.id === newState.selectedUserId)) {
                    newState.selectedUserId = null
                }
                if (newState && newState.data && newState.data.deals && ! newState.data.deals.find(d => d.id === newState.selectedDealId)) {
                    newState.selectedDealId = null
                }
                if (newState.data) {
                    newState.data.filteredDeals = filterDeals(newState)
                }
                return newState
            default:
                return state
        }
    }

    const store = createStore(dataReducer)
    return store
}

function normalizeUserId(user) {
    if (! user) {
        return user
    }
    if (user.indexOf("@") != -1) {
        user = user.substring(0, user.indexOf("@"))
    }
    user = user.replaceAll(" ", "_")
    return user.toLowerCase()
}

const filters = {
    "my_team": (deal, state) => {
        const myTeamDeal = deal.team.find(a => state.data.myTeam.indexOf(normalizeUserId(a.user_id)) != -1)
        if (! myTeamDeal) {
            return false
        } else {
            return true
        }
    },
    "goes_3days": (deal, state) => {
        if (! deal.duedate) {
            return false
        }
        const in3days = moment().add(3, "days").endOf("day")
        const dateStr = deal.duedate
        const date = moment(dateStr, DATE_PATTERN)
        return date.isBefore(in3days)
    },

    "goes_this_week": (deal, state) => {
        if (! deal.duedate) {
            return false
        }
        const endOfThisWeek = moment().endOf("week")
        const dateStr = deal.duedate
        const date = moment(dateStr, DATE_PATTERN)
        return date.isBefore(endOfThisWeek)
    },

    "goes_next_week": (deal, state) => {
        if (! deal.duedate) {
            return false
        }
        const startOfNextWeek = moment().add(1, "week").startOf("week")
        const endOfNextWeek = moment().add(1, "week").endOf("week")
        const dateStr = deal.duedate
        const date = moment(dateStr, DATE_PATTERN)
        return date.isBefore(endOfNextWeek) && date.isAfter(startOfNextWeek)
    }

}

const filterDeals = (state) => {
    let result = state.data.deals 
    if (state.filter) {
        const filteringFn = filters[state.filter]
        if (filteringFn) {
            result = state.data.deals.filter((deal) => {
                return filteringFn(deal, state)
            })
        }
    }
    if (state.regionsFilter && state.regionsFilter.length > 0) {
        result = result.filter((deal) => {
            if (state.includeBlankRegion && (deal.region == null || deal.region === "" || deal.region === undefined)) {
                return true //show deals w/o region for any combination of regions
            }
            for (let r of state.regionsFilter) {
                if (deal.region == null) {
                    return false
                }
                if (deal.region.indexOf(r) != -1) {
                    return true
                }
            }
            return false
        })        
    }
    return result
}



export const SET_DEAL_TAB = 'SET_DEAL_TAB'
export const SET_FILTER = 'SET_FILTER'
export const SET_SELECTED_USER = 'SET_SELECTED_USER'
export const SET_SELECTED_USER_AND_THEIR_DEAL = 'SET_SELECTED_USER_AND_THEIR_DEAL'
export const SET_SELECTED_DEAL = 'SET_SELECTED_DEAL'
export const SET_LOADING = 'SET_LOADING'
export const SET_WORKLOAD = 'SET_WORKLOAD'
export const TOGGLE_REGION_FILTER = "TOGGLE_REGION_FILTER"
export const TOGGLE_INCLUDE_BLANK_REGION_FILTER = "TOGGLE_INCLUDE_BLANK_REGION_FILTER"
export const CLEAR_ALL_FILTERS = "CLEAR_ALL_FILTERS"

export const setDealTabInProgress = {type: SET_DEAL_TAB, value: 'in_progress'}
export const setDealTabInitiatives = {type: SET_DEAL_TAB, value: 'initiatives'}
export const setDealTabIntake = {type: SET_DEAL_TAB, value: 'pipeline'}
export const setLoadingTrue = {type: SET_LOADING, value: true}
export const setLoadingFalse = {type: SET_LOADING, value: false}

export default createAppStore
