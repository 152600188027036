import React from 'react';
import './LoginView.css';

function LoginView({signIn, error}) {
    return (
        <section className="jumbotron text-center">
            <div className="container">
            <h1>DSCVA Login page</h1>
            <p className="lead text-muted">Digital Solution Consulting Virtual Agent uses EPAM single sign on to log you in. Click the button below to login. v1.114</p>
            {error && <div class="alert alert-warning" role="alert">
                <p>Error during login:</p>
                <p>{error}</p>
            </div>}
            <p>
                <a href="#" className="btn btn-primary my-2" onClick={() => signIn(false)}>Login</a>
            </p>
            </div>
        </section>        
    )
}

export default LoginView;