import React, { useRef, useState, useEffect } from 'react';
import { BsFunnel, BsFunnelFill } from 'react-icons/bs';
import {useSelector, useDispatch } from 'react-redux'
import {SET_FILTER, CLEAR_ALL_FILTERS, TOGGLE_REGION_FILTER, TOGGLE_INCLUDE_BLANK_REGION_FILTER} from './../redux/store'

function FilterSelection({api}) {

    const [dropShow, setDropShow] = useState(false)
    const filter = useSelector(state => state.filter)
    const regionsFilter = useSelector(state => state.regionsFilter)
    const includeBlankRegion = useSelector(state => state.includeBlankRegion)
    const regions = useSelector(state => state.data.regions) || []
    const dispatch = useDispatch()
    const ref = useRef(this);

    const setFilter = (filterName) => {
        api.getPersonalSetting().then((personalSettings) => {
            setDropShow(false)
            dispatch({type: SET_FILTER, value: filterName, myTeam: personalSettings.myTeam})
        });
    }

    const clearFilters = () => {
        dispatch({type: CLEAR_ALL_FILTERS})
        setDropShow(false)
    }

    const toggleRegionFilter = (region) => {
        dispatch({type: TOGGLE_REGION_FILTER, value: region})
    }

    const toggleIncludeBlankRegion = () => {
        dispatch({type: TOGGLE_INCLUDE_BLANK_REGION_FILTER})
    }

    useEffect(() => {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setDropShow(false)
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return (
    <div ref={ref} class="btn-group" role="group">
        <button type="button" className="nav-link btn dropdown-toggle"  onClick={() => setDropShow(! dropShow)}>
            {filter === null && regionsFilter.length === 0 ? <BsFunnel/> : <BsFunnelFill color="#ffc107"/>}
            
        </button>
        <div className={dropShow ? "dropdown-menu show" : "dropdown-menu"}>
            {[{id: "goes_3days", caption: "Goes in 3 days"},
             {id: "goes_this_week", caption: "Goes this week"},
             {id: "goes_next_week", caption: "Goes the next week"},
             {id: "divider"},
             {id: "my_team", caption: "My team only"},
             {id: "divider"},
            ].map((config, idx) => {
                    if (config.id === "divider") return (<div id={"filter-dropdown-" + idx} className="dropdown-divider"></div>);
                    const clsName = config.id === filter ? "dropdown-item active" : "dropdown-item"
                    return <a key={"filter-dropdown-" + idx} onClick={() => setFilter(config.id)} className={clsName} href="#">{config.caption}</a> 
                }
             )}
            {regions.map(r => {
                const clsName = regionsFilter.indexOf(r) != -1 ? "dropdown-item active" : "dropdown-item"
                return <a onClick={() => toggleRegionFilter(r)} className={clsName} href="#">{r}</a> 
                }
            )}
            {regionsFilter.length > 0 && <a onClick={() => toggleIncludeBlankRegion()} className="dropdown-item" href="#">
                <input type="checkbox" onClick={e => e.preventDefault()} checked={includeBlankRegion}></input>&nbsp;
                With an empty region
            </a>}
            <div className="dropdown-divider"></div>
            <a onClick={() => clearFilters()} className="dropdown-item" href="#">Clear</a>
        </div>
    </div>
    )

}

export default FilterSelection;