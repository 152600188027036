import React from "react";
import Octicon from 'react-octicon'

function JointEffortIndicator({deal}) {
    const show = deal.jointEffort != null
    if (show) {
        return (
            <div className="user-component">
                <Octicon name="organization" mega/>
                <span className="user-component-caption">{deal.jointEffort}</span>
            </div>
        )
    } else {
        return null
    }
}

export default JointEffortIndicator;