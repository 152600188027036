import React from 'react';
import WorkloadView from './WorkloadView';
import ReportingView from './ReportingView';
import PlanningView from './PlanningView';
import CalendarView from './CalendarView';
import apiConstructor from '../api'
import {
    BrowserRouter as Router,
    Switch,
    Route
  } from "react-router-dom";

function MainView({getAuthToken, signIn, signOut, account}) {
    const api = apiConstructor({getAuthToken, signIn, account})

    return <Router>
        <Switch>
            <Route path="/reports">
                <ReportingView api={api} signOut={signOut} account={account}/>
            </Route>
            <Route path="/planning">
                <PlanningView api={api} signOut={signOut} account={account}/>
            </Route>
            <Route path="/calendar">
                <CalendarView api={api} signOut={signOut} account={account}/>
            </Route>
            <Route path="/">
                <WorkloadView api={api} signOut={signOut} account={account}/>            
            </Route>
        </Switch>
    </Router>
}

export default MainView;