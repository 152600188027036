import React, { useState, useEffect } from "react";
import Octicon from "react-octicon"
import { useDrop } from 'react-dnd'
import User from "./User";
import DealAssignDropTarget from "./DealAssignDropTarget";
import Dialog from "./Dialog";
import {sortTeamByAssignee} from './../utils/utils'

import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import "react-datepicker/dist/react-datepicker.css";

import { useDispatch, useSelector } from 'react-redux'
import {SET_SELECTED_USER, SET_WORKLOAD, setLoadingTrue, setLoadingFalse, setDealTabInProgress, setDealTabIntake} from './../redux/store'
import {executeApi} from './../utils/utils'
import DealFlaggingDlg from "./dlg/DealFlaggingDlg";

const Moment = require('moment-timezone')

const MOMENT_DATE_PATTERN = 'YYYY-MM-DD'

function userCanBeAssigned(deal, user) {
  return user !== null && (deal.team.find((a) => a.user_id === user.id) ? false : true)
}

const PlusToggle = React.forwardRef(({ children, onClick }, ref) => (
  <span ref={ref} onClick={(e) => {e.preventDefault(); onClick(e);}} alt="Add tag" className="badge badge-primary add-tag">
              <Octicon name="plus"/>
              {children}
  </span>
));

function DealDetails({dealId, refresh, idPrefix='dealdetails', tags, api}) {
  
  const followUpTypes = useSelector(state => state.data.followUpTypes)
  const dispatch = useDispatch()

  const deals = useSelector(state => state.data.deals)

  let deal = null
  if (dealId) {
    deal = deals.find(d => d.id === dealId)
  }
  const dealType = deal ? deal.type : null
  const writable = useSelector(state => state.data.writable) && (deal === null || dealType !== "PlannerTask")  

  const [{ isOver, isOverCurrent }, drop] = useDrop({
		accept: 'user',
		drop: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
          return
      }
      executeApi(writable, dispatch, refresh, () => {
        return api.assignDealToUser(deal.id, item.user_id, item.role)
      })
    },
		collect: monitor => ({
			isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
		}),
  })

  const getUserRoleCaption = (deal, assignment) => {
    const result = []
    
    if (assignment.role == "pm") {
      if (assignment.user_id === deal.assignee) {
        result.push("Primary PM")
      } else {
        result.push("Secondary PM")
      }
      if (assignment.user_id === deal.assignee && assignment.user_id === deal.reviewer) {
        result.push("Reviewer")
      }
    } 
    if (assignment.role == "reviewer") {
      result.push("Reviewer")
    }
    if (assignment.role == "ba") {
      result.push("BA")
    }
    if (assignment.role == "sa") {
      result.push("SA")
    }

    return result
  }

  const showReviewerBadge = (assignment, deal) => {
    if (deal.reviewer === assignment.user_id) {
      if (assignment.role === "reviewer") {
        return true
      }
      //if a user has a specific reviewer assignment, then don't display it on a non-reviewer assignment
      if (deal.reviewer != deal.assignee && deal.team.find(a => a.user_id === deal.reviewer && a.role === "reviewer")) {
        return false
      } else {
        return true
      }
    }
    return false
  }

  const getFieldsForDialog = (deal) => {
    return deal ? {
      duedate: deal.duedate ? Moment(deal.duedate, MOMENT_DATE_PATTERN) : null,
      caption: deal.caption,
      comment: deal.comment
    } : {}
  }

  const getFieldsForSave = () => {
    //TODO format to config
    const initialData = getFieldsForDialog(deal)
    const duedate = dlgDueDate ? Moment(dlgDueDate).format(MOMENT_DATE_PATTERN) : null
    const caption = dlgSummary
    const comment = dlgComment
    
    const res = {}
    if (duedate != Moment(initialData.duedate).format(MOMENT_DATE_PATTERN)) {
      res.duedate = duedate
    }
    if (caption != initialData.caption) {
      res.caption = caption
    }
    if (comment != initialData.comment) {
      res.comment = comment
    }
    return res
  }

  const users = useSelector(state => state.data.users)
  const selectedUserId = useSelector(state => state.selectedUserId)
  const selectedUser = users.find(u => u.id === selectedUserId) || null

  const [showDealEditDlg, setShowDealEditDlg] = useState(false)
  
  let [dlgDueDate, setDlgDueDate] = useState(null)
  let [dlgSummary, setDlgSummary] = useState(null)
  let [dlgComment, setDlgComment] = useState(null)
  
  const [showDealFlaggingConfirm, setShowDealFlaggingConfirm] = useState(false)

  const openDealEditDlg = () => {
    const fields = getFieldsForDialog(deal)
    setDlgDueDate(fields.duedate ? fields.duedate.toDate() : undefined);
    setDlgSummary(fields.caption);
    setDlgComment(fields.comment || "")
    setShowDealEditDlg(true)
  }
  
  const saveDealDetails = () => {
    setShowDealEditDlg(false)
    const fields = getFieldsForSave(deal)
    if (fields.duedate != undefined || fields.caption != undefined || fields.comment != undefined) {
      executeApi(writable, dispatch, refresh, () => {
        return api.updateDealDetails(dealId, {duedate: fields.duedate, caption: fields.caption, comment: fields.comment})
      })
    }
  }

  const startWorkingOnDeal = () => {
    executeApi(writable, dispatch, refresh, () => {
      return api.updateDealDetails(deal.id, {pipeline: 'in_progress'})
    })
  }

  const stopWorkingOnDeal = () => {
    executeApi(writable, dispatch, refresh, () => {
      return api.updateDealDetails(deal.id, {pipeline: 'intake'})
    })
  }

  function saveDealFlag({operation, 
                  createFollowUp,
                  createType,
                  createSummary,
                  createDueDate,
                  createEpic,
                  keepReviewer, keepPM, keepBA, keepSA
  }) {
    setShowDealFlaggingConfirm(false)
    if (operation == "flag") {
      let create = null
      if (createFollowUp) {
        create = {
          type: createType,
          caption: createSummary,
          dueDate: createDueDate
        }
      }
      function internalRefresh() {
        return refresh(createFollowUp)
      }
      executeApi(writable, dispatch, internalRefresh, () => {
        return api.updateDealDetails(dealId, {flagged: true, flagEpic: ! createFollowUp}).then(() => {
          if (createFollowUp) {
            const fields = {caption: createSummary, dueDate: createDueDate, epic: createEpic};
            if (keepReviewer && deal.reviewer) {
              fields.reviewer = deal.reviewer;
            }
            if (keepPM) {
              if (deal.assignee) {
                fields.pm = deal.assignee;
              } else {
                const a = deal.team.find(a => a.role == "pm");
                if (a) {
                  fields.pm = a.user_id;
                }
                
              }
            }
            if (keepBA) {
              const a = deal.team.find(a => a.role == "ba");
              if (a) {
                fields.ba = a.user_id;
              }
            }
            if (keepSA) {
              const a = deal.team.find(a => a.role == "sa");
              if (a) {
                fields.sa = a.user_id;
              }
            }
            return api.createDeal(createType, fields)
          }
        })
      })
    } else if (operation == "delete") {
      executeApi(writable, dispatch, refresh, () => {
        return api.deleteDeal(dealId)
      })
    }
  }
  
  const onAddTag = (tagName) => {
    executeApi(writable, dispatch, refresh, () => {
      return api.addTag(dealId, tagName)
    })
  }

  const onRemoveTag = (tagName) => {
    executeApi(writable, dispatch, refresh, () => {
      return api.removeTag(dealId, tagName)
    })
  }

  const removeUserFromDeal = (deal_id, user_id, role) => {
    executeApi(writable, dispatch, refresh, () => {
      return api.removeUserFromDeal(deal_id, user_id, role)
    })
  }

  const assignDealToUser = (deal_id, user_id, role) => {
    executeApi(writable, dispatch, refresh, () => {
      return api.assignDealToUser(deal_id, user_id, role)
    })
  }

  const renderDealId = (deal) => {
    if (deal.type === "PlannerTask") {
      return <></>
    } else {
      return <>{deal.id.startsWith("new") ? "new" : deal.id}</>
    }
  }

  const renderEditIcon = (deal) => {
    if (deal && deal.type === "PlannerTask") {
      const [_, taskId] = deal.id.split(":");
      const link = `https://tasks.office.com/EPAM.onmicrosoft.com/Home/Task/${taskId}?Type=TaskLink&Channel=Link`;
      return <a style={{ color: 'inherit' }} href={link} target="_blank"><Octicon name="pencil"/></a>
    } else {
      return <>{writable && <Octicon onClick={openDealEditDlg} name="pencil"/>}</>
    }
  }

  if (! deal) {
    return (
      <div>None</div>
    )
  }

  let icon = "checklist"
  let dateBadgeClassName;
  if (deal.type === "Initiative" || deal.type === "PlannerTask") {
    dateBadgeClassName = "badge-success"
    icon = "telescope"
  } else {
    dateBadgeClassName = "badge-primary"
  }

  return (
    <div className={isOver && writable ? 'deal-details deal-over' : 'deal-details'} ref={drop}>
      <div className="card" id={`${idPrefix}-${deal.id}`}>
        <div className="card-header">
        <Octicon name={icon}/> &nbsp;
        {
          deal.pipeline === 'intake' && 
          <Octicon name="primitive-square"/>
        }                        
        &nbsp;
        {
          deal.isEpic === false &&
          <span>
            <span alt="Due date" className={`edit-deal badge ${dateBadgeClassName}`}>{deal.duedate ? deal.duedate : 'No Date'}
            &nbsp;&nbsp;
            {renderEditIcon(deal)}
            </span>
            &nbsp;
          </span>
        }
        {
          writable && (deal.tags || []).length === 0 &&
          <span>
            <Dropdown onSelect={onAddTag}>
              <Dropdown.Toggle as={PlusToggle} id="dropdown-custom-components"></Dropdown.Toggle>

              <Dropdown.Menu as={ButtonGroup}>
                {tags.map(tagInfo => {
                  return (<Dropdown.Item eventKey={tagInfo.name}>{tagInfo.caption}</Dropdown.Item>)
                })}
              </Dropdown.Menu>
            </Dropdown>            
            &nbsp;
          </span>
        }
        {
          (deal.tags || []).length !== 0 && 
          <span>
          {deal.tags.map(tag => {
            const tInfo = tags.find(t => t.name == tag)
            return (
              <span alt={tag} className={"badge " + (tInfo.cls || "badge-primary")}>{tInfo.caption}
                {writable && <>&nbsp;<Octicon onClick={() => onRemoveTag(tag)} name="x"/></>}
              </span>
            )
          })}
          </span>
        }       

        {deal.caption} &nbsp;
        </div>
        <div className="card-body">
          <div className="team">
            {sortTeamByAssignee(deal)
            .filter(assignment => ! (deal.reviewer && deal.reviewer === deal.assignee && deal.reviewer === assignment.user_id && assignment.role === "reviewer"))
            .map((assignment, i) => (
              <div key={i}>
                <User 
                  leader={deal.assignee === assignment.user_id && assignment.role === "pm"}
                  reviewer={showReviewerBadge(assignment, deal)}
                  key={i} user_id={assignment.user_id} role={assignment.role} 
                  onClick={() => dispatch({type: SET_SELECTED_USER, value: assignment.user_id })} 
                  onRemove={(writable && assignment.role != "reviewer") ? () => removeUserFromDeal(deal.id, assignment.user_id, assignment.role) : null}
                  roleCaption={getUserRoleCaption(deal, assignment)}
                  inline={false}></User>
              </div>
            ))}
            {isOver && <DealAssignDropTarget dealId={deal.id} role="reviewer" api={api} writable={writable} refresh={refresh}/>}
          </div>
        </div>
        <div className="card-footer">
          <span className="badge badge-secondary">{renderDealId(deal)}</span>
          &nbsp;
          {deal.region && <span className="badge badge-secondary">{deal.region.join(", ")}</span>}
          &nbsp;
          {deal.type && <span className="badge badge-secondary">{deal.type}</span>}
          &nbsp;
          {(dealType != "Initiative" && dealType != "PlannerTask" && dealType != "Epic" && ! deal.epic) && <span className="badge badge-warning">No epic</span>}
        </div>
        {deal.comment && <div className="card-footer">
          <Octicon name="comment"/>
          &nbsp;
          {deal.comment.split("\n").map(l => <p>{l}</p>)}
        </div>}

      </div>
      {
      writable &&
      <React.Fragment>
      <div className="deal-details-actions">
        <div className="assignment-actions btn-toolbar">
          {userCanBeAssigned(deal, selectedUser) &&
          <button onClick={() => assignDealToUser(deal.id, selectedUser.id, selectedUser.role)} className="btn btn-sm btn-primary" type="submit">Assign <User inline={true} users={users} user_id={selectedUser.id}></User></button>
          }
          {deal.isEpic === false && deal.pipeline === 'intake' &&
           <button onClick={() => startWorkingOnDeal()} className="btn btn-sm btn-primary ml-2" type="submit"><Octicon size="small" name="triangle-right" mega/>Start Working on Deal</button>
          }
          {deal.isEpic === false && (deal.pipeline === 'in_progress' || deal.pipeline === 'on_hold') &&
           <button onClick={() => stopWorkingOnDeal()} className="btn btn-sm btn-primary ml-2" type="submit"><Octicon size="small" name="primitive-square" mega/>Stop Working on Deal</button>
          }
          <button onClick={() => setShowDealFlaggingConfirm(true)} className="btn btn-sm btn-danger ml-2" type="button"><Octicon size="small" name="x" mega/>Flag / Delete</button>
        </div>
      </div>

      <Dialog title={deal.caption} show={showDealEditDlg} onClose={() => setShowDealEditDlg(false)} onSave={saveDealDetails}>
        <form>
        <div class="form-group">
          <label>Deal summary &nbsp;</label>
          <input className="form-control" type="text" value={dlgSummary} onChange={(e) => setDlgSummary(e.target.value)}/>
        </div>
        <div class="form-group">
          <label>Due date &nbsp;</label>
          <DatePicker className="form-control" selected={dlgDueDate} onChange={(date) => {setDlgDueDate(date)}}/>
        </div>
        <div class="form-group">
          <label>Comment &nbsp;</label>
          <textarea className="form-control" value={dlgComment} onChange={(e) => setDlgComment(e.target.value)}></textarea>
        </div>

        </form>
      </Dialog>
          <DealFlaggingDlg deal={deal}
            followUpTypes={followUpTypes}
            show={showDealFlaggingConfirm} 
            setShow={setShowDealFlaggingConfirm}
            save={saveDealFlag}/>
      </React.Fragment>
      }
    </div>
  );
}
export default DealDetails;